export const clientMixin = {
  data: () => ({
    clientCount: 1,
    clients: [],
    members: 1,
    error: null,
    accounts: null,
    checks: null,
    success: false
  }),
  methods: {
    deleteAccount (idx) {
      this.accounts.splice(idx, 1)
      this.checks.splice(idx, 1)
      this.clientCount--
    },
    check (idx, el) {
      this.checks[idx][el] = !this.accounts[idx][el] || null
      this.success = !this.checkError()
    },
    checkError (set = false) {
      for (const [idx, object] of this.accounts.entries()) {
        for (const key in object) {
          if (this.members === 1 && key === 'client') { continue }
          if (
            this.accounts[idx][key] === null ||
            this.accounts[idx][key] === ''
          ) {
            if (set) this.checks[idx][key] = true
            return idx
          }
        }
      }
      return false
    },
    changePrices (array, keys, parse = false) {
      for (const [idx, object] of array.entries()) {
        for (const key in object) {
          if (key in keys && array[idx][key]) {
            array[idx][key] = parse
              ? this.parseNumber(array[idx][key])
              : this.formatNumber(array[idx][key])
          }
        }
      }
      return array
    },
    prepareScheme (scheme) {
      if (this.accounts.length > 0) {
        this.clientCount = this.accounts.length
        this.checks = this.accounts.map((c) =>
          JSON.parse(JSON.stringify(scheme))
        )
      } else {
        this.accounts = [JSON.parse(JSON.stringify(scheme))]
        this.checks = [JSON.parse(JSON.stringify(scheme))]
      }
    },
    addAccount (scheme) {
      this.accounts.push(JSON.parse(JSON.stringify(scheme)))
      this.checks.push(JSON.parse(JSON.stringify(scheme)))
      this.success = false
      this.clientCount++
    },
    saveClientData (url, nextlink, service) {
      const requestData = {
        user: this.$store.state.user.id || null,
        service: this.$store.state.services[service],
        data: this.accounts
      }
      this.$store.commit('loading', true)
      this.axios.post(url, requestData)
        .then(() => {
          this.$store.commit('loading', false)
          this.$router.push(nextlink)
        })
        .catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
    }
  }
}
